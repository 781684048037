/*eslint-disable no-undef*/
// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

function render_map($el) {
  // var
  var $markers = $el.find('.marker');
  var styles = [
    {
      featureType: 'all',
      elementType: 'labels.text.fill',
      stylers: [
        {
          saturation: 36,
        },
        {
          color: '#000000',
        },
        {
          lightness: 40,
        },
      ],
    },
    {
      featureType: 'all',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          visibility: 'on',
        },
        {
          color: '#000000',
        },
        {
          lightness: 16,
        },
      ],
    },
    {
      featureType: 'all',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#000000',
        },
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#000000',
        },
        {
          lightness: 17,
        },
        {
          weight: 1.2,
        },
      ],
    },
    {
      featureType: 'landscape',
      elementType: 'geometry',
      stylers: [
        {
          color: '#000000',
        },
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#000000',
        },
        {
          lightness: 21,
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#000000',
        },
        {
          lightness: 17,
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#000000',
        },
        {
          lightness: 29,
        },
        {
          weight: 0.2,
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry',
      stylers: [
        {
          color: '#000000',
        },
        {
          lightness: 18,
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'geometry',
      stylers: [
        {
          color: '#000000',
        },
        {
          lightness: 16,
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'geometry',
      stylers: [
        {
          color: '#000000',
        },
        {
          lightness: 19,
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#000000',
        },
        {
          lightness: 17,
        },
      ],
    },
  ];

  // vars
  var args = {
    zoom: 13,
    center: new google.maps.LatLng(0, 0),
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    scrollwheel: false,
    styles: styles, // Uncomment for map styling
  };

  // create map
  var map = new google.maps.Map($el[0], args);

  // add a markers reference
  map.markers = [];

  // add markers
  $markers.each(function () {
    add_marker($(this), map);
  });

  // center map
  center_map(map);
}

function add_marker($marker, map) {
  // var
  var latlng = new google.maps.LatLng(
    $marker.attr('data-lat'),
    $marker.attr('data-lng')
  );

  // create marker
  var marker = new google.maps.Marker({
    position: latlng,
    map: map,
    icon: $marker.data('marker-icon'), //uncomment if you use custom marker
  });

  // add to array
  map.markers.push(marker);

  // if marker contains HTML, add it to an infoWindow
  if ($.trim($marker.html())) {
    // create info window
    var infowindow = new google.maps.InfoWindow();

    // show info window when marker is clicked
    google.maps.event.addListener(marker, 'click', function () {
      // Close previously opened infowindow, fill with new content and open it
      infowindow.close();
      infowindow.setContent($marker.html());
      infowindow.open(map, marker);
    });
  }
}

/*
 *  This function will center the map, showing all markers attached to this map
 */

function center_map(map) {
  // vars
  var bounds = new google.maps.LatLngBounds();

  // loop through all markers and create bounds
  $.each(map.markers, function (i, marker) {
    var latlng = new google.maps.LatLng(
      marker.position.lat(),
      marker.position.lng()
    );
    bounds.extend(latlng);
  });

  // only 1 marker?
  if (map.markers.length == 1) {
    // set center of map
    map.setCenter(bounds.getCenter());
  } else {
    // fit to bounds
    map.fitBounds(bounds);
  }
}

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

const $megaToggle = $('.header__main .mega-toggle a');
const $megaMenu = $('#mega-menu');
const $megaToggleMobile = $('.header-mobile .mega-toggle > a');
const $megaMenuMobile = $('#mega-menu-mobile');
const $headerLogo = $('.header__logo');

function megaMenuPosition() {
  const $el = $('.header__menu--left');
  const bottom = $el.position().top + $el.outerHeight(true);
  $megaMenu.css('top', `${bottom - 2}px`);
}

function closeMegaMenu() {
  $megaMenu.fadeOut();
  $megaToggle.removeClass('mega-active');
  $headerLogo.removeClass('mega-active');
}

function closeMegaMenuMobile() {
  $megaMenuMobile.slideUp();
  $megaToggleMobile.removeClass('mega-active');
}

const $searchToggle = $('.header__main .menu-search a');
const $searchParent = $('.header__main .menu-search');

function closeSearch() {
  $searchParent.removeClass('search-active');
}

function addNumberInputButtons() {
  const $formWrapper = $('.sell-to-us__form-outer');
  if (!$formWrapper.length) return;
  const $fieldWrapper = $formWrapper.find(
    '.sell-to-us__quantity .ginput_container'
  );
  const $input = $fieldWrapper.find('input[type=number]');
  $input.val(1);
  const $buttonMinusHtml =
    '<button type="button" class="sell-to-us__qty-button qty-btn-minus">' +
    '<svg xmlns="http://www.w3.org/2000/svg" width="11.427" height="20" viewBox="0 0 11.427 20">\n' +
    '  <path id="chevron-right-solid" d="M74.934,40.916a1.43,1.43,0,0,1,0,2.022l-8.569,8.569a1.43,1.43,0,1,1-2.022-2.022l7.56-7.56-7.556-7.56a1.43,1.43,0,1,1,2.022-2.022l8.569,8.569Z" transform="translate(75.352 51.925) rotate(-180)" fill="#b3b3b3"/>\n' +
    '</svg>' +
    '</button>';
  const $buttonPlusHtml =
    '<button type="button" class="sell-to-us__qty-button qty-btn-plus">' +
    '<svg xmlns="http://www.w3.org/2000/svg" width="11.427" height="20" viewBox="0 0 11.427 20">\n' +
    '  <path id="chevron-right-solid" d="M74.934,40.916a1.43,1.43,0,0,1,0,2.022l-8.569,8.569a1.43,1.43,0,1,1-2.022-2.022l7.56-7.56-7.556-7.56a1.43,1.43,0,1,1,2.022-2.022l8.569,8.569Z" transform="translate(-63.925 -31.925)" fill="rgba(25,25,25,0.6)"/>\n' +
    '</svg>\n' +
    '</button>';
  $(document).on('gform_post_render', function () {
    const $fieldWrapper = $formWrapper.find(
      '.sell-to-us__quantity .ginput_container'
    );
    const buttonMinus = document.querySelectorAll('.qty-btn-minus');
    const buttonPlus = document.querySelectorAll('.qty-btn-plus');
    if (!buttonMinus.length || !buttonPlus.length) {
      $fieldWrapper.prepend($buttonMinusHtml);
      $fieldWrapper.append($buttonPlusHtml);
      $(document).on('click', function (e) {
        $fieldWrapper.each(($i, wrapper) => {
          const buttonMinus = wrapper.querySelector('.qty-btn-minus');
          const buttonPlus = wrapper.querySelector('.qty-btn-plus');
          const $input = $(wrapper).find('input[type=number]');
          if (buttonMinus.contains(e.target) || buttonPlus.contains(e.target)) {
            if ($input.length === 0) return;
            const max = +$input.attr('max');
            const min = +$input.attr('min');
            const step = 1;
            const val = +$input.val();
            if (buttonPlus.contains(e.target)) {
              if (max && max <= val) {
                $input.val(max);
              } else {
                $input.val(val + step);
                buttonMinus.querySelector('path').style.fill =
                  'rgba(25,25,25,0.6)';
              }
            } else if (buttonMinus.contains(e.target)) {
              if (min && min >= val) {
                $input.val(min);
              } else if (val > 1) {
                $input.val(val - step);
                if (min + 1 === val) {
                  buttonMinus.querySelector('path').style.fill =
                    'rgb(179,179,179)';
                }
              }
            }
            $input.trigger('change');
          }
        });
      });
    }
  });
}

addNumberInputButtons();

function formsToggle() {
  const select = document.getElementById('form-toggle');
  const elements = document.querySelectorAll('.js-form-toggle');
  if (!select || !elements) return;
  select.addEventListener('change', function () {
    select
      .querySelectorAll('option')
      .forEach((option) => option.classList.remove('form-select-active'));
    const activeOption = this.options[this.options.selectedIndex];
    const activeOptionData = activeOption.value;
    activeOption.classList.add('form-select-active');
    elements.forEach((el) =>
      el.dataset.form !== activeOptionData
        ? (el.style.display = 'none')
        : (el.style.display = 'block')
    );
  });
  formDataHandler();
}

function sellCTAButtonsHandler() {
  const buttons = document.querySelectorAll('.sell-cta__link');
  if (!buttons) return;
  buttons.forEach((button) =>
    button.addEventListener('click', () => {
      if (button.dataset.form) {
        localStorage.setItem('data-form', button.dataset.form);
        formDataHandler();
      }
    })
  );
}

function formDataHandler() {
  const dataForm = localStorage.getItem('data-form');
  const select = document.getElementById('form-toggle');
  if (!select || !dataForm) return;
  select.value = dataForm;
  select.dispatchEvent(new Event('change'));
  localStorage.removeItem('data-form');
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  formsToggle();
  sellCTAButtonsHandler();

  $("a[href='#top']").click(function () {
    $('html, body').animate({ scrollTop: 0 }, 'slow');
    return false;
  });

  $(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();
    if ($(this).is($('.tabs-title-link'))) return;
    if ($(this).attr('href').length <= 1) return;
    $('html, body').animate(
      {
        scrollTop: $($.attr(this, 'href')).offset().top - 100,
      },
      400
    );
  });

  $('.acf-map').each(function () {
    render_map($(this));
  });

  document.addEventListener('facetwp-loaded', function () {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    $('.archive .variations_form').each(function () {
      $(this).wc_variation_form();
    });
  });

  function reviews() {
    const $reviewsSlider = $('#reviews');
    if ($reviewsSlider.length === 0) return;
    let $currentSlide;

    $reviewsSlider.on('init reInit', function (event, slick, currentSlide) {
      const $statusCurrent = $('.reviews__cur');
      const $statusMax = $('.reviews__max');
      const $progressLine = $('.reviews__progress-line');
      const $progressLineWidth = $progressLine.width();
      const $progressActive = $('.reviews__progress-active');
      const $maxSlides = slick.slideCount;
      const i = (currentSlide ? currentSlide : 0) + 1;
      $currentSlide = currentSlide;
      $statusCurrent.text(i);
      $statusMax.text('/' + $maxSlides);
      const $progressActiveWidth = $progressLineWidth / $maxSlides;
      $progressActive.css({
        width: `${$progressActiveWidth}px`,
        left: `${$progressActiveWidth * currentSlide}px`,
      });

      $(window).on('resize', function () {
        // const $statusCurrent = $('.reviews__cur');
        // const $statusMax = $('.reviews__max');
        const $progressLine = $('.reviews__progress-line');
        const $progressLineWidth = $progressLine.width();
        const $progressActive = $('.reviews__progress-active');
        const $progressActiveWidth = $progressLineWidth / $maxSlides;
        $progressActive.css({
          width: `${$progressActiveWidth}px`,
          left: `${$progressActiveWidth * $currentSlide}px`,
        });
      });
    });
    $reviewsSlider.on(
      'beforeChange',
      function (event, slick, currentSlide, nextSlide) {
        const $statusCurrent = $('.reviews__cur');
        const $statusMax = $('.reviews__max');
        const $progressLine = $('.reviews__progress-line');
        const $progressLineWidth = $progressLine.width();
        const $progressActive = $('.reviews__progress-active');
        const $maxSlides = slick.slideCount;
        $currentSlide = nextSlide;
        const i = (currentSlide ? nextSlide : 1) + 1;
        $statusCurrent.text(i);
        $statusMax.text('/' + $maxSlides);
        const $progressActiveWidth = $progressLineWidth / $maxSlides;
        $progressActive.css({
          width: `${$progressActiveWidth}px`,
          left: `${$progressActiveWidth * nextSlide}px`,
        });
      }
    );

    $reviewsSlider.slick({
      autoplay: false,
      slidesToShow: 2,
      slidesToScroll: 1,
      arrows: false,
      dots: false,
      rows: 0,
      responsive: [
        {
          breakpoint: 1300,
          settings: {
            autoplay: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: false,
            rows: 0,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            autoplay: false,
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: false,
            dots: false,
            rows: 0,
          },
        },
        {
          breakpoint: 768,
          settings: {
            autoplay: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: false,
            rows: 0,
          },
        },
      ],
    });

    // $('.reviews__next').click(function () {
    //   $reviewsSlider.slick('slickNext');
    // });

    $('.reviews__prev').click(function () {
      $reviewsSlider.slick('slickPrev');
    });

    $('.reviews__next').click(function () {
      $reviewsSlider.slick('slickNext');
    });
    $('.reviews__prev').addClass('slick-disabled');
    $reviewsSlider.on('afterChange', function () {
      if ($('.slick-prev').hasClass('slick-disabled')) {
        $('.reviews__prev').addClass('slick-disabled');
      } else {
        $('.reviews__prev').removeClass('slick-disabled');
      }
      if ($('.slick-next').hasClass('slick-disabled')) {
        $('.reviews__next').addClass('slick-disabled');
      } else {
        $('.reviews__next').removeClass('slick-disabled');
      }
    });
  }

  reviews();

  // Partners Slider

  // $('.continuous').slick({
  //   speed: 9000,
  //   autoplay: true,
  //   autoplaySpeed: 0,
  //   cssEase: 'linear',
  //   slidesToShow: 5,
  //   slidesToScroll: 1,
  //   variableWidth: true,
  //   arrows: false,
  //   dots: false,
  //   rows: 0,
  //   infinite: true,
  //   pauseOnHover: false,
  // });
  let slickSettings = {
    speed: 10000,
    autoplay: true,
    autoplaySpeed: 0,
    centerMode: false,
    cssEase: 'linear',
    slidesToShow: 2,
    slidesToScroll: 1,
    draggable: false,
    focusOnSelect: false,
    pauseOnFocus: false,
    pauseOnHover: false,
    variableWidth: true,
    infinite: true,
    arrows: false,
    buttons: false,
    dots: false,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          speed: 5000,
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          autoplay: true,
          draggable: true,
          focusOnSelect: true,
          pauseOnFocus: true,
          pauseOnHover: true,
          centerMode: true,
        },
      },
    ],
  };

  $('.continuous').slick(slickSettings);

  function megaMenuToggle() {
    $megaToggle.on('click', function (e) {
      e.preventDefault();
      megaMenuPosition();
      $(this).toggleClass('mega-active');
      $megaMenu.fadeToggle();
      $headerLogo.toggleClass('mega-active');
    });
    $(document).on('click', function (e) {
      if (
        $megaToggle.is(e.target) ||
        $megaMenu.is(e.target) ||
        $megaMenu.has(e.target).length > 0 ||
        $megaToggle.has(e.target).length > 0
      )
        return;
      closeMegaMenu();
    });
    $(document).keydown(function (event) {
      if (event.keyCode == 27) {
        $megaMenu.fadeOut();
        $megaToggle.removeClass('mega-active');
      }
    });
  }

  megaMenuToggle();

  // Mobile mega menu
  function mobileMegaMenuToggle() {
    $megaToggleMobile.on('click', function (e) {
      e.preventDefault();
      $(this).toggleClass('mega-active');
      $megaMenuMobile.slideToggle();
    });
    $(document).on('click', function (e) {
      if (
        $megaToggleMobile.is(e.target) ||
        $megaMenuMobile.is(e.target) ||
        $megaMenuMobile.has(e.target).length > 0 ||
        $megaToggleMobile.has(e.target).length > 0
      )
        return;
      closeMegaMenuMobile();
    });
  }

  mobileMegaMenuToggle();

  function searchToggle() {
    $searchToggle.on('click', function (e) {
      e.preventDefault();
      $searchParent.toggleClass('search-active');
    });
    $(document).on('click', function (e) {
      if (
        $searchToggle.is(e.target) ||
        $searchParent.is(e.target) ||
        $searchParent.has(e.target).length > 0 ||
        $searchToggle.has(e.target).length > 0
      )
        return;
      closeSearch();
    });
    $(document).keydown(function (event) {
      if (event.keyCode == 27) {
        closeSearch();
      }
    });
  }

  searchToggle();

  $('.header-top__cart').on('click', function () {
    $('.mini__cart-wrapper').slideDown();
  });

  $(document).on('click', function (e) {
    if (
      !(
        $(e.target).closest('.mini__cart-wrapper').length > 0 ||
        $(e.target).closest('.header-top__cart').length > 0
      )
    ) {
      $('.mini__cart-wrapper').slideUp();
    }
  });
  $(document).on('click', function (event) {
    if ($('.promo-code-open').is(event.target)) {
      $('.coupon-total').slideToggle();
    }
    if ($('.button-cloned').is(event.target)) {
      $('.hide-button').click();
      return false;
    }
    if ($('.cloned-input').is(event.target)) {
      $('.cloned-input').on('input', function () {
        var value = $(this).val();
        $('.input-text').val(value);
      });
    }
  });

  function updateMiniCart() {
    $.post(
      woocommerce_params.ajax_url,
      { action: 'update_mini_cart' },
      function (response) {
        $('#mode-mini-cart').html(response);
      }
    );
  }

  // /* global wc_add_to_cart_params */
  /* global woocommerce_params */
  updateMiniCart();

  $(document).on('click', function (event) {
    if (
      $('.quantity .button-plus , .quantity .button-minus ').is(event.target)
    ) {
      const target = event.target;
      const qty = $(target).siblings('input.qty');
      if (qty.length === 0) return;
      const max = +qty.attr('max');
      const min = +qty.attr('min');
      const step = +qty.attr('step');
      const val = +qty.val();

      if ($('.button-plus').is(event.target)) {
        if (max && max <= val) {
          qty.val(max);
        } else {
          qty.val(val + step);
        }
      } else if ($('.button-minus').is(event.target)) {
        if (min && min >= val) {
          qty.val(min);
        } else if (val > 1) {
          qty.val(val - step);
        }
      }
      qty.trigger('change');
      setTimeout(() => {
        $('.update_cart').click();
        return false;
      }, 2000);
    }
  });

  function updateShopPageQuantity() {
    $('.products').on('click', '.quantity input', function () {
      return false;
    });
    $('.products').on('change input', '.quantity .qty', function () {
      var add_to_cart_button = $(this)
        .parents('.product')
        .find('.add_to_cart_button');
      // For AJAX add-to-cart actions
      add_to_cart_button.attr('data-quantity', $(this).val());
      // For non-AJAX add-to-cart actions
      add_to_cart_button.attr(
        'href',
        '?add-to-cart=' +
          add_to_cart_button.attr('data-product_id') +
          '&quantity=' +
          $(this).val()
      );
    });
    // Trigger on Enter press
    $('.products').on('keypress', '.quantity .qty', function (e) {
      if ((e.which || e.keyCode) === 13) {
        $(this)
          .parents('.product')
          .find('.add_to_cart_button')
          .trigger('click');
      }
    });
  }

  updateShopPageQuantity();

  $(' .accordion-content iframe').css('opacity', '0');
  $('.cover-image , .play').on('click', function () {
    $('.accordion-content iframe').attr(
      'src',
      $('iframe').attr('src').replace('?feature=oembed', '?autoplay=1')
    );
    $('.cover-image').fadeOut();
    $(' .accordion-content iframe').fadeIn();
    $(' .accordion-content iframe').css('opacity', '1');
    $('.play').fadeOut();
  });

  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if (!$target.length) return;
    const elOffset = $target.offset().top;
    const elHeight = $target.height();
    const windowHeight = $(window).height();
    let offset;

    if (elHeight < windowHeight) {
      offset = elOffset - (windowHeight / 2 - elHeight / 2);
    } else {
      offset = elOffset;
    }
    $('html, body').animate({ scrollTop: offset }, 500);
    return false;
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
      closeMegaMenu();
      closeMegaMenuMobile();
      closeSearch();
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
      closeMegaMenu();
      closeMegaMenuMobile();
      closeSearch();
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here
  megaMenuPosition();

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here
  megaMenuPosition();

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
